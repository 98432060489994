import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { useAuthContext } from '../../hooks/useAuthContext'
import * as bloodKitsApi from '../../api/health/bloodKits'

const useSetup = () => {
	const { user } = useAuthContext()
	const [loading, setLoading] = useState(true)
	const [lifestyle, setLifestyle] = useState()
	const [focus, setFocus] = useState()
	const [kits, setKits] = useState()

	const fetchFocus = async () => {
		const response = await fetch(`/api/health/api/focus/${user._id}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${user.token}`
			}
		})

		if (response.status === 204) return setFocus()

		const json = await response.json()

		if (!response.ok) return toast.error("Couldn't load your focus area", { description: json.error })

		setFocus(json)
	}

	const fetchKits = async () => {
		try {
			const response = await bloodKitsApi.getKits(user._id, user.token)

			const json = await response.json()

			if (!response.ok) throw new Error(json.error)

			setKits(json)
		} catch (error) {
			toast.error("Problem getting blood kits", { description: error.message })
			if (process.env.NODE_ENV !== 'production') console.error(error)
		}
	}

	const fetchLifestyle = async () => {
		const response = await fetch(`/api/health/api/assessments/${user._id}/lifestyle`, {
			headers: {
				Authorization: `Bearer ${user.token}`
			}
		})

		if (response.status === 204) return

		const json = await response.json()

		if (!response.ok)
			return toast.error("Couldn't load your lifestyle assessmnents", { description: json.error })

		setLifestyle(json)
	}

	const getData = async () => {
		if (!user) return setLoading(false)
		await fetchKits()
		await fetchLifestyle()
		await fetchFocus()
		setLoading(false)
	}

	useEffect(() => {
		getData()
	}, [])

	return {
		loading,
		lifestyle,
		focus,
		kits
	}
}

export default useSetup
